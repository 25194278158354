import React, { useState } from "react";
import { Text } from "../../styles/Common";
import { CustomSelect, StyledOption } from "./styles";
import { theme } from "../../styles/theme";
import { SearchIcon } from "../../assets/icons";
import { ChevronDownIcon } from "../../assets/icons";

export const Select = ({
  label,
  required ,
  options,
  defaultValue,
  placeholder,
  helper,
  noborder,
  paddingx,
  showSearch,
  notFoundContent,
  filterOption,
  optionFilterProp,
  optionLabelProp,
  error,
  disabled,
  onChange,
  onSearch,
  height,
  weightLabel,
  sizeLabel,
  allowClear,
  colorLabel,
  colorIcon,
  ...rest
}) => {
  const [iconName, setIconName] = useState("arrow-down");

  const handleFocusOnSearch = () => showSearch && setIconName("search");
  const handleBlurOnSearch = () => showSearch && setIconName("arrow-down");

  return (
    <div>
      {label && label !== "" && (
        <Text
          weight={weightLabel}
          size={sizeLabel || theme.fonts.size.default}
          color={colorLabel || theme.colors.gray200}
          mt="0px"
          mb="7px"
          ml="6px"
          align="left"
        >
          {label} {required  && (<span style={{ color: theme.colors.red }}>*</span>)}
        </Text>
      )}
      <CustomSelect
        options={options}
        status={error ? "error" : ""}
        defaultValue={defaultValue}
        placeholder={placeholder}
        suffixIcon={
          showSearch ? (
            <SearchIcon stroke={colorIcon || theme.colors.gray400} />
          ) : (
            <ChevronDownIcon
              width="20px"
              height="20px"
              stroke={colorIcon || theme.colors.gray200}
            />
          )
        }
        noborder={noborder}
        paddingx={paddingx}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        disabled={disabled ? 1 : 0}
        onChange={onChange}
        onSearch={onSearch}
        height={height}
        onFocus={handleFocusOnSearch}
        onBlur={handleBlurOnSearch}
        allowClear={allowClear}
        dropdownStyle={{
          borderRadius: "14px",
        }}
        {...rest}
      />

      {helper && (
        <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
