import React, { useState } from "react";
import { PageWrapper } from "./styles";
import { Div, Text, Col, Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import cellPhone from "../../assets/images/Group 102@2x.png";
import { useNavigate } from "react-router-dom";
import { Collapse } from "../../components/Collapse";
import { Card } from "../../components/Card";
import waveHome from "../../assets/images/waveHome.svg";
import sparkles from "../../assets/images/sparkles.svg";
import backImage from "../../assets/images/backgroundHome.png";
import { useOutletContext } from "react-router-dom";
import Carousel from "../../components/Carousel/Index";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { AiOutlineAppstore, AiOutlineGift, AiOutlineRocket, AiOutlineShoppingCart} from "react-icons/ai";

export const Home = () => {
  const { isMobile } = useOutletContext();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(["1"]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const fadeInUp = {
    hidden: { opacity: 0, y: isSmallScreen ? 0 : 50 }, 
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const animationProps = isSmallScreen
  ? { initial: { opacity: 1 }, animate: { opacity: 1 } } 
  : { initial: "hidden", whileInView: "visible", variants: fadeInUp };

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: "1",
      header: "Si me inscribo, ¿voy a recibir la caja o premios?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          Al inscribirte, tendrás la oportunidad de participar en el envío de PulpeyBox y 
          también de acceder a sorteos de premios, productos y descuentos. ¡Mientras más 
          información brindes, más oportunidades tendrás de recibir una caja! 
        </Text>
      ),
    },
    {
      key: "2",
      header: "¿Tengo que pagar para participar?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          ¡No tienes que pagar nada! Solo debes registrarte y estar atento a
          nuestras redes sociales y actividades. Queremos que disfrutes de los
          productos sin ningún costo adicional.
        </Text>
      ),
    },
    {
      key: "3",
      header: "¿Me tengo que inscribir todos los meses?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          No, solo necesitas inscribirte una vez. A partir de ahí, ya estarás
          dentro de nuestra comunidad y podrás ser seleccionado para recibir
          futuras cajas y participar en nuestras dinámicas.
        </Text>
      ),
    },
    {
      key: "4",
      header: "¿Mis datos serán compartidos?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          Tus datos están seguros con nosotros. No los compartimos con terceros 
          y los utilizamos únicamente para mejorar tu experiencia. 
        </Text>
      ),
    },
    {
      key: "5",
      header: "¿La PulpeyBox es el único beneficio?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          ¡No! Además de recibir la PulpeyBox, también podrás participar en
          sorteos de premios, recibir productos exclusivos y acceder a
          descuentos especiales. Siempre habrá algo nuevo para ti.
        </Text>
      ),
    },
    {
      key: "6",
      header: "¿Cómo saber si fui seleccionado para recibir una PulpeyBox?",
      panel: (
        <Text color={theme.colors.white} size={theme.fonts.size.h6}>
          Recibirás un correo de Pulpey de preselección. Es importante que sigas 
          los pasos indicados en el correo lo antes posible para asegurar que 
          podamos enviarte tu PulpeyBox. Nuestro aliado logístico, se encargará 
          de llevar el pedido hasta tu casa. Asegúrate de proporcionar una dirección 
          clara y detallada, incluyendo cualquier indicación que facilite la entrega. 
          Mantente atento a tu celular, ya que el repartidor puede contactarte o 
          visitarte en cualquier momento para realizar la entrega. 
        </Text>
      ),
    },
  ];

  return (
    <PageWrapper isMobile={isMobile}>
      {!isMobile && (
        <>
          <img
            src={backImage}
            alt="backimage"
            width={"100%"}
            height={"768px"}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: -2,
            }}
          />

          <img
            src={waveHome}
            alt="wave home"
            width={"100%"}
            style={{
              position: "absolute",
              top: "709px",
              right: "0",
              zIndex: -2,
            }}
          />

          <Div
            height={"799px"}
            width={!isMobile ? "356px" : "50%"}
            background={theme.colors.red}
            radius={"0 0 44px 44px"}
            style={{
              position: "absolute",
              left: "30%",
              top: "437px",
              zIndex: -1,
            }}
          />

          <img
            src={sparkles}
            alt="sparkles home"
            style={{
              position: "absolute",
              top: "750px",
              right: "0",
              zIndex: -1,
            }}
          />

          <Div
            height={"1230px"}
            width={"100%"}
            background={theme.colors.blue700}
            radius={"90px 0 0 0"}
            style={{
              position: "absolute",
              left: "0px",
              top: "830px",
              zIndex: -2,
            }}
          />
        </>
      )}
      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={!isMobile ? "28px 98px" : "15px 17px"}
        align="center"
        m="0 0 20px 0"
        style={{ backdropFilter: "blur(16px)" }}
      >
        <img
          src={logo}
          alt="logo"
          width={!isMobile ? "154.65px" : "100px"}
          height={"35.33px"}
        />
        <Div gap={!isMobile ? "18px" : "5px"} align="center">
          <Button
            width={!isMobile ? "157px" : "100px"}
            height={"40px"}
            variant={"outlined"}
            onClick={() => navigate("insights")}
          >
            Corporativo
          </Button>
          <Button
            width={!isMobile ? "157px" : "80px"}
            height={"40px"}
            onClick={() => navigate("login")}
          >
            {!isMobile ? "Login / Registro" : "Login"}
          </Button>
        </Div>
      </Div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Div
          width={!isMobile ? "1171px" : "100%"}
          height={!isMobile ? "318px" : "auto"}
          background={theme.colors.whiteBlur}
          radius={"44px"}
          p={!isMobile ? "39px 0 49px 47px" : "40px"}
          direction="column"
          m={!isMobile ? "0 0 120px 0" : "0 0 40px 0"}
        >
          <Text
            size={theme.fonts.size.h1i}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb={"19px"}
          >
            Bienvenido a Pulpey
          </Text>
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.white}
            mb={"25px"}
            style={{ width: !isMobile ? "498px" : "100%" }}
          >
            La plataforma interactiva que te conecta con tus marcas favoritas,
            llevándote experiencias hasta la puerta de tu casa.
          </Text>
          <Button width={"196px"} onClick={() => navigate("/user-register")}>
            Quiero registrarme
          </Button>
        </Div>
      </motion.div>
      {!isMobile && (
        <motion.img
          src={cellPhone}
          alt="cellphone"
          width="652.87px"
          height="482.62px"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          style={{ position: "absolute", top: "128.88px", right: "69.94px" }}
        />
      )}
      {isMobile && <Div gap="23px" m="0 0 75px 0">
        <Col>
          {/* <img
            src={pulpeyBox}
            alt="Pulpey Box"
            radius="68px"
            width={"402px"}
            height={"639px"}
          /> */}
          <Carousel
            width={"325.33px"}
            max_width={"325.33px"} 
            width_image={"325.33px"}
            height_image={"500px"}
          />
        </Col>
      </Div>}
      <Div gap="23px" m="0 0 75px 0" width={isMobile && "100%"}>
        {!isMobile && (
          <Col>
            {/* <img
              src={pulpeyBox}
              alt="Pulpey Box"
              radius="68px"
              width={"402px"}
              height={"639px"}
            /> */}
            <Carousel />
          </Col>
        )}
        <motion.div
          {...animationProps}
          transition={{ delay: 1 * 0.2 }}
        >
          <Col>
            <Div
              width={!isMobile ? "746px" : "100%"}
              height={"auto"}
              background={theme.colors.whiteBlur}
              radius="44px"
              p={!isMobile ? "36px 25px 32px 33px" : "40px"}
              direction="column"
              m="0 0 32px 0"
              style={{ backdropFilter: "blur(30px)" }}
            >
              <Text
                size={theme.fonts.size.h3}
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                mb="30px"
              >
                ¿Qué es Pulpey?
              </Text>
              <Text size={theme.fonts.size.h6} color={theme.colors.white}>
              Somos el medio de conexión entre las marcas y la comunidad Pulpey. Brindamos 
              una experiencia única en Guatemala en la cual podrás recibir{" "}
              <span style={{ color: theme.colors.red }}>
              productos y promociones
              </span>{" "}
              en tu casa a cambio de tu opinión.<br /> <br /> 
              Todas las opiniones son importantes, con{" "}
              <span style={{ fontWeight: theme.fonts.weight.bold }}>
                Pulpey
              </span>, le puedes decir a las marcas 
              lo que piensas, brindando feedback inmediato que luego procesamos para que tu click 
              sea una voz. 
              </Text>
            </Div>
            <Text
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              color={theme.colors.white}
              mb="30px"
            >
              ¿Qué podrás recibir?
            </Text>
            <Div
              gap="18px"
              m="0 0 16px 0"
              direction={!isMobile ? "row" : "column"}
            >
              <Div
                align="center"
                gap="13px"
                width={!isMobile ? "364px" : "100%"}
                height={"92px"}
                background={theme.colors.whiteBlur}
                radius="46px"
                p="17px 54px 17px 16px"
                style={{ backdropFilter: "blur(30px)" }}
              >
                <Div
                  background={theme.colors.blue500}
                  radius="50px"
                  width="58px"
                  height="58px"
                  align="center"
                  justify="center"
                >
                  <AiOutlineShoppingCart
                    size={28}
                    color={theme.colors.white}
                  />
                </Div>
                <Text size={theme.fonts.size.h4} color={theme.colors.white}>
                  Productos conocidos
                </Text>
              </Div>
              <Div
                align="center"
                gap="13px"
                width={!isMobile ? "364px" : "100%"}
                height={"92px"}
                background={theme.colors.whiteBlur}
                radius="46px"
                p="17px 25px 17px 16px"
                style={{ backdropFilter: "blur(30px)" }}
              >
                <Div
                  background={theme.colors.blue500}
                  radius="50px"
                  width="58px"
                  height="58px"
                  align="center"
                  justify="center"
                  style={{ minWidth: "58px" }}
                >
                  <AiOutlineRocket
                    size={28}
                    color={theme.colors.white}
                  />
                </Div>
                <Text
                  size={theme.fonts.size.h4}
                  color={theme.colors.white}
                  align={isMobile && "left"}
                >
                  Productos que aún no han salido al mercado
                </Text>
              </Div>
            </Div>
            <Div gap="18px" direction={!isMobile ? "row" : "column"}>
              <Div
                align="center"
                gap="13px"
                width={!isMobile ? "364px" : "100%"}
                height={"92px"}
                background={theme.colors.whiteBlur}
                radius="46px"
                p="17px 54px 17px 16px"
                style={{ backdropFilter: "blur(30px)" }}
              >
                <Div
                  background={theme.colors.blue500}
                  radius="50px"
                  width="58px"
                  height="58px"
                  align="center"
                  justify="center"
                >
                  <AiOutlineGift
                    size={28}
                    color={theme.colors.white}
                  />
                </Div>
                <Text size={theme.fonts.size.h4} color={theme.colors.white}>
                  Promociones
                </Text>
              </Div>
              <Div
                align="center"
                gap="13px"
                width={!isMobile ? "364px" : "100%"}
                height={"92px"}
                background={theme.colors.whiteBlur}
                radius="46px"
                p="17px 15px 17px 16px"
                style={{ backdropFilter: "blur(30px)" }}
              >
                <Div
                  background={theme.colors.blue500}
                  radius="50px"
                  width="58px"
                  height="58px"
                  align="center"
                  justify="center"
                >
                  <AiOutlineAppstore
                    size={28}
                    color={theme.colors.white}
                  />
                </Div>
                <Text
                  size={theme.fonts.size.h4}
                  color={theme.colors.white}
                  align="left"
                >
                  Artículos promocionales
                </Text>
              </Div>
            </Div>
          </Col>
        </motion.div>
      </Div>
      <Text
        size={theme.fonts.size.h3}
        color={theme.colors.white}
        weight={theme.fonts.weight.bold}
        mb="32px"
      >
        Preguntas frecuentes
      </Text>
      <motion.div
        {...animationProps}
        transition={{ delay: 1 * 0.2 }}
      >
        <Card
          background={theme.colors.blue50}
          radius="32px"
          p={"26px 45px 10px 42px"}
          width={!isMobile ? "1171px" : "100%"}
          m={"0 0 150px 0"}
        >
          <Collapse
            activeKey={activeKey}
            handlePanelChange={handlePanelChange}
            panels={items}
            group={true}
            marginBottom={"0"}
          />
        </Card>
      </motion.div>
    </PageWrapper>
  );
};
