import styled from "styled-components";
import { theme } from "../../styles/theme";

export const FooterContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  position: absolute;
  bottom: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  margin-bottom: 5px; 

  &::before,
  &::after {
    content: "";
    width: 50px;
    height: 1px;
    background-color: ${theme.colors.gray400};
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }

  a {
    color: ${theme.colors.gray200};
    font-size: 1.5em;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: ${theme.colors.red}; 
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const TermsLink = styled.a`
  color: ${theme.colors.gray200};
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  font-size: ${theme.fonts.size.sm};
  &:hover {
    color: ${theme.colors.red}; 
    transform: scale(1.1);
  }
`;
