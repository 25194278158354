import React, { useState, useEffect, useRef } from 'react';
import { useAnimationFrame } from 'framer-motion';

import brand1 from "../../../assets/brands/beehub-gt.png";
import brand2 from "../../../assets/brands/buckner-gt.png";
import brand3 from "../../../assets/brands/CBC-LOGO.png";
import brand4 from "../../../assets/brands/chocokrispis.png";
import brand5 from "../../../assets/brands/costabrava.png";
import brand6 from "../../../assets/brands/ducal.png";
import brand7 from "../../../assets/brands/EL-BARRETAL-LOGO.png";
import brand8 from "../../../assets/brands/frutibotonetas.png";
import brand9 from "../../../assets/brands/gomy.png";
import brand10 from "../../../assets/brands/goya.png";
import brand11 from "../../../assets/brands/hounds.png";
import brand12 from "../../../assets/brands/instawax.png";
import brand13 from "../../../assets/brands/lacondesa.png";
import brand14 from "../../../assets/brands/limpio.png";
import brand15 from "../../../assets/brands/malher.png";
import brand16 from "../../../assets/brands/MARUQUITA-LOGO.png";
import brand17 from "../../../assets/brands/maxqueso.png";
import brand18 from "../../../assets/brands/Pringles.png";
import brand19 from "../../../assets/brands/pro.png";
import brand20 from "../../../assets/brands/prodin.png";
import brand21 from "../../../assets/brands/tampicocero.png";
import brand22 from "../../../assets/brands/yummusfood.png";
import brand23 from "../../../assets/brands/zucaritas.png";
import brand24 from "../../../assets/brands/HENKEL-LOGO.png";
import brand25 from "../../../assets/brands/PERSIL-LOGO.png";
import brand26 from "../../../assets/brands/MAS-LOGO.png";
import brand27 from "../../../assets/brands/MI-SUPER-FRESH-LOGO.png";
import brand28 from "../../../assets/brands/turin.png";
import brand29 from "../../../assets/brands/colgate.png";
import brand30 from "../../../assets/brands/kellogs.png";
import brand31 from "../../../assets/brands/banzitos.png";
import brand32 from "../../../assets/brands/huggies.png";
import brand33 from "../../../assets/brands/regia.png";
import brand34 from "../../../assets/brands/bite.png";
import brand35 from "../../../assets/brands/hidravida.png";
import brand36 from "../../../assets/brands/isdin.png";
import brand37 from "../../../assets/brands/gnc.png";
import brand38 from "../../../assets/brands/mars.png";
import { BrandImage, CarouselContainer, CarouselItem, CarouselTrack, GradientOverlay } from '../styles';

const brands = [
  { id: 1, src: brand1, alt: "Beehub GT" },
  { id: 2, src: brand2, alt: "Buckner GT" },
  { id: 24, src: brand24, alt: "Henkel" },
  { id: 25, src: brand25, alt: "Persil" },
  { id: 26, src: brand26, alt: "Mas" },
  { id: 27, src: brand27, alt: "Mi Super Fresh" },
  { id: 28, src: brand28, alt: "Turin" },
  { id: 4, src: brand38, alt: "Mars" },
  { id: 34, src: brand34, alt: "Bite" },
  { id: 35, src: brand35, alt: "Hidravida" },
  { id: 36, src: brand36, alt: "Isdin" },
  { id: 37, src: brand37, alt: "GCN" },
  { id: 29, src: brand29, alt: "Colgate" },
  { id: 30, src: brand30, alt: "Kellogs" },
  { id: 31, src: brand31, alt: "Banzitos" },
  { id: 32, src: brand32, alt: "Huggies" },
  { id: 33, src: brand33, alt: "Regia" },
  { id: 3, src: brand3, alt: "CBC" },
  { id: 5, src: brand5, alt: "Costa Brava" },
  { id: 12, src: brand12, alt: "Instawax" },
  { id: 15, src: brand15, alt: "Malher" },
  { id: 16, src: brand16, alt: "Maruquita" },
  { id: 18, src: brand18, alt: "Pringles" },
  { id: 19, src: brand19, alt: "Pro" },
  { id: 20, src: brand20, alt: "Prodin" },
  { id: 22, src: brand22, alt: "Yummus Food" }
];

const Carousel = ({isMobile}) => {
  const containerRef = useRef(null);
  const carouselRef = useRef(null);
  const xPos = useRef(0);
  const VELOCITY = 1.2; 
  const itemWidth = 220; 

  useAnimationFrame(() => {
    if (!containerRef.current || !carouselRef.current) return;

    xPos.current -= VELOCITY;
    
    
    if (Math.abs(xPos.current) >= itemWidth) { 
      const itemsToMove = Math.floor(Math.abs(xPos.current) / itemWidth);
      xPos.current += itemWidth * itemsToMove;
      
      const items = Array.from(carouselRef.current.children);
      for (let i = 0; i < itemsToMove; i++) {
        const item = items[i];
        carouselRef.current.appendChild(item);
      }
    }

    carouselRef.current.style.transform = `translateX(${xPos.current}px)`;
  });

  return (
    <CarouselContainer ref={containerRef}>
      <GradientOverlay isMobile={isMobile} />
      <CarouselTrack ref={carouselRef}>
        {brands.map((brand, index) => (
          <CarouselItem key={`${brand.id}-${index}`}>
            <BrandImage src={brand.src} alt={brand.alt} />
          </CarouselItem>
        ))}
      </CarouselTrack>
    </CarouselContainer>
  );
};

export default Carousel;