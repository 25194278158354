import React from 'react';
import { motion } from 'framer-motion';
import { Div, Text, Row, Col } from "../../../styles/Common";
import { theme } from '../../../styles/theme';
import { useOutletContext } from 'react-router-dom';

const TitleSection = ({ title, description }) => {
  const titleWords = title.split(" ");
  const { isMobile } = useOutletContext();
  
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1
      }
    }
  };

  const wordVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const descriptionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        delay: 0.4
      }
    }
  };

  const highlightWords = (text) => {
    return text.split(' ').map((word, index) => {
      const isHighlight = word.includes('insights') || 
                         word.includes('análisis') || 
                         word.includes('estratégicas') ||
                         word.includes('optimizar') ||
                         word.includes('marketing') ||
                         word.includes('KPIs');
      return (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 0.03 }}
          style={{
            color: isHighlight ? '#FE4F51' : 'inherit',
          }}
        >
          {word}{' '}
        </motion.span>
      );
    });
  };

  return (
    <Div
      direction="column"
      m={!isMobile ? "0 0 15px 0" : "0 0 30px 0"}
      width="100%"
      style={{
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={containerVariants}
        style={{
          position: 'relative',
          zIndex: 2
        }}
      >
        <Div style={{ overflow: 'hidden', marginBottom: '19px', display: 'flex', flexWrap: 'wrap' }}>
          {titleWords.map((word, index) => (
            <motion.div
              key={index}
              variants={wordVariants}
              style={{
                display: 'inline-block',
                marginRight: isMobile ? '10px' : '15px',
                position: 'relative'
              }}
            >
              <Text
                size={isMobile? theme.fonts.size.h3 :theme.fonts.size.h1i}
                color={theme.colors.red}
                weight={theme.fonts.weight.semibold}
                style={{
                  letterSpacing: '-0.02em'
                }}
              >
                {word}
              </Text>
              {index === 0 && (
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  transition={{ delay: 0.8, duration: 0.5 }}
                  style={{
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    height: '4px',
                    background: '#FE4F51',
                    borderRadius: '2px'
                  }}
                />
              )}
            </motion.div>
          ))}
        </Div>

        <motion.div variants={descriptionVariants}>
          <Text
            size={theme.fonts.size.h3}
            color={theme.colors.white}
            mt="23.61px"
          >
            {highlightWords(`${description}`)}
          </Text>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.1, scale: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
        style={{
          position: 'absolute',
          top: '-50%',
          right: '-20%',
          width: '500px',
          height: '500px',
          background: 'radial-gradient(circle, #FE4F51 0%, transparent 70%)',
          filter: 'blur(80px)',
          zIndex: 1
        }}
      />
    </Div>
  );
};

export default TitleSection;