import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Modal } from "../../../components/Modal";
import { Text, Row, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { TimerIcon, VerifyIcon } from "../../../assets/icons";
import { Input } from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../../../utilities/helpers";
import useSendEmailOtp from "../hooks/useSendEmailOtp";
import { setTokenRequested } from "../../../core/store/auth";

const ModalOTP = ({ showModalOTP, handleCloseModal, isMobile }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    setFocus,
    watch,
    formState: {},
  } = useForm();

  const dispatch = useDispatch();

  const tokenRequested = useSelector((state) => state.auth.tokenRequested);

  const sendEmailOtp = useSendEmailOtp();

  const [showResendButton, setShowResendButton] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [expirationTime, setExpirationTime] = useState(1800);

  useEffect(() => {
    if (isCodeValid) {
      return;
    }
    if (timeLeft <= 0) {
      setShowResendButton(true);
      return;
    }

    const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearTimeout(timer);
  }, [timeLeft, isCodeValid]);

  useEffect(() => {
    if (showModalOTP) {
      setTimeLeft(180);
      setShowResendButton(false);
      setExpirationTime(1800);
    }
  }, [showModalOTP]);

  useEffect(() => {
    if (isCodeValid) {
      return;
    }
    if (expirationTime <= 0) {
      setShowResendButton(true);
      return;
    }
    const timer = setTimeout(() => setExpirationTime(expirationTime - 1), 1000);
    return () => clearTimeout(timer);
  }, [expirationTime, isCodeValid]);

  const watchedFields = watch(["code1", "code2", "code3", "code4", "code5"]);
  useEffect(() => {
    const enteredCode = watchedFields.join("");

    const decryptedToken = tokenRequested?.token
      ? decrypt(tokenRequested?.token)
      : null;
    const isCompleted = watchedFields.every(
      (item) => item !== "" && item !== undefined && item !== null
    );

    if (enteredCode !== "" && enteredCode === decryptedToken) {
      setIsCodeValid(true);
    } else if (isCompleted && enteredCode !== decryptedToken) {
      setErrorCode(true);
    } else {
      setIsCodeValid(false);
      setErrorCode(false);
    }
  }, [watchedFields, tokenRequested]);

  const handleRequestOtp = () => {
    const action = sendEmailOtp;
    const dataToSend = {
      email: tokenRequested?.dataToSend.email,
      isPasswordReset: true,
    };
    action.reset();
    action.mutate(dataToSend, {
      onSuccess: (res) => {
        setTimeLeft(120);
        setExpirationTime(1800);
        setShowResendButton(false);
        const codes = ["code1", "code2", "code3", "code4", "code5"];
        codes.forEach((code) => {
          setValue(code, "");
        });
        dispatch(
          setTokenRequested({
            recoveryMethod: tokenRequested?.recoveryMethod,
            dataToSend: tokenRequested?.dataToSend,
            token: res?.data?.result,
          })
        );
        toast.success("Se ha reenviado el código");
      },
      onError: (err) => {
        toast.error(err?.response?.data?.title || err?.response?.data);
      },
    });
  };

  const handleChangeOtp = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value.length === 1) {
      setFocus(`code${nextStep}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(`input[name="code${nextStep}"]`);
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };
  const handleDelete = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value === "") {
      setFocus(`code${nextStep - 2}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="code${nextStep - 2}"]`
        );
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };

  const handleCloseAndReset = () => {
    setShowResendButton(false);
    setIsCodeValid(false);
    setErrorCode(false);
    const codes = ["code1", "code2", "code3", "code4", "code5"];
    codes.forEach((code) => {
      setValue(code, "");
    });
    handleCloseModal();
  };

  const handleOtp = () => {
    navigate("/reset-password");
  };

  const handlePaste = (event, fields) => {
    event.preventDefault(); 
    const pastedText = event.clipboardData.getData("text"); 
    const digits = pastedText.replace(/\D/g, "").split(""); 

    digits.slice(0, 5).forEach((digit, index) => {
      const fieldName = `code${index + 1}`;
      setValue(fieldName, digit); 
    });

    if (digits.length >= 5) {
      setFocus("code5", { shouldSelect: true });
    } else if (digits.length > 0) {
      setFocus(`code${digits.length}`, { shouldSelect: true });
    }
  };

  return (
    <Modal
      open={showModalOTP}
      onCancel={handleCloseAndReset}
      height="auto"
      width={!isMobile ? "611px" : "100%"}
    >
      <Modal.Header
        title={"Recupera tu cuenta"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 24px 0"}
      />
      <Modal.Body>
        <Text size={theme.fonts.size.h6} color={theme.colors.gray200} mb="13px">
          Indica el código de verificación que hemos enviado
        </Text>
        <form onSubmit={handleSubmit(handleOtp)}>
          <Row m="0 0 24px 0" justify="center">
            <Div
              background={theme.colors.yellowlight}
              radius="20px"
              width="84px"
              height="40px"
              align="center"
              justify="center"
            >
              <TimerIcon
                width={"24px"}
                height={"24px"}
                stroke={theme.colors.blue500}
              />
              <Text
                color={theme.colors.blue500}
                weight={theme.fonts.weight.semibold}
              >
                {Math.floor(timeLeft / 60)}:
                {String(timeLeft % 60).padStart(2, "0")}
              </Text>
            </Div>
          </Row>
          <Div
            gap="16px"
            m={showResendButton ? "0 0 8px 0" : "0 0 24px 0"}
            align="center"
            justify="center"
            direction={!isMobile ? "row" : "column"}
          >
            <Row gap="16px" align="center" justify="center">
              <Controller
                name="code1"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={!isMobile ? "45px" : "42px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    onlyNumber
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 2);
                    }}
                    onPaste={(event) => handlePaste(event, field)}
                  />
                )}
              />
              <Controller
                name="code2"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={!isMobile ? "45px" : "42px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 3);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 3);
                      }
                    }}
                    onPaste={(event) => handlePaste(event, field)}
                  />
                )}
              />
              <Controller
                name="code3"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={!isMobile ? "45px" : "42px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 4);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 4);
                      }
                    }}
                    onPaste={(event) => handlePaste(event, field)}
                  />
                )}
              />
              <Controller
                name="code4"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={!isMobile ? "45px" : "42px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 5);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 5);
                      }
                    }}
                    onPaste={(event) => handlePaste(event, field)}
                  />
                )}
              />
              <Controller
                name="code5"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={!isMobile ? "45px" : "42px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 6);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 6);
                      }
                    }}
                    onPaste={(event) => handlePaste(event, field)}
                  />
                )}
              />
            </Row>
            {isCodeValid && (
              <Div
                background={
                  expirationTime === 0
                    ? theme.colors.white50
                    : theme.colors.green100
                }
                radius="20px"
                width="141px"
                height="40px"
                align="center"
                justify="center"
                border={
                  expirationTime === 0
                    ? `1px solid ${theme.colors.red}`
                    : `1px solid ${theme.colors.green300}`
                }
                gap="10px"
              >
                {expirationTime !== 0 && (
                  <VerifyIcon stroke={theme.colors.green300} />
                )}
                {expirationTime === 0 ? (
                  <Text color={theme.colors.red}>Vencido</Text>
                ) : (
                  <Text color={theme.colors.green300}>Verificado</Text>
                )}
              </Div>
            )}
          </Div>
          {showResendButton && (
            <Row m="0 0 24px 0" justify="center">
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.red}
                style={{ cursor: "pointer" }}
                onClick={handleRequestOtp}
              >
                Reenviar código
              </Text>
            </Row>
          )}
          {errorCode && (
            <Row m="0 0 24px 0" justify="center">
              <Text size={theme.fonts.size.sm} color={theme.colors.red}>
                Código inválido
              </Text>
            </Row>
          )}
          <Button
            width={"165px"}
            htmlType="submit"
            disabled={!isCodeValid || expirationTime === 0}
          >
            Continuar
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOTP;
