import React, { forwardRef } from "react";
import { Text } from "../../styles/Common";
import { CustomInput } from "./styles";
import { theme } from "../../styles/theme";

import InputGroup from "./Group";

export const Input = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onPressEnter,
  width,
  label,
  required,
  sizeLabel,
  weightLabel,
  colorLabel,
  onlyNumber,
  margin,
  ...rest
}) => {
  return (
    <div style={{ width, margin: margin }}>
      {label && (
        <Text
          weight={weightLabel}
          size={sizeLabel || theme.fonts.size.sm}
          color={colorLabel || theme.colors.gray500}
          mt="0px"
          mb="7px"
          ml="6px"
          align="left"
        >
          {label} {required && (<span style={{ color: theme.colors.red }}>*</span>)}
        </Text>
      )}
      <CustomInput
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onPressEnter={onPressEnter}
        autoComplete="none"
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};

Input.Group = InputGroup;
