import React from "react";
import { PageWrapper } from "./styles";
import { Div, Text, Col, Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { PackingIcon } from "../../assets/icons";
import { useOutletContext } from "react-router-dom";
import BrandCarousel from "./components/brand";
import {motion} from "framer-motion";
import { useMediaQuery } from "react-responsive";
import AboutSection from "./components/about";
import Item from "antd/es/list/Item";
import TitleSection from "./components/title-sections";
import { AiOutlineBarChart, AiOutlineExperiment, AiOutlineEye, AiOutlineFund, AiOutlinePieChart } from "react-icons/ai";


export const Insights = () => {
  const navigate = useNavigate();
  const { isMobile } = useOutletContext();
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const fadeInUp = {
    hidden: { opacity: 0, y: isSmallScreen ? 0 : 50 }, // No mover en pantallas pequeñas
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  
  const hoverEffect = {
    scale: 1.05,
    transition: { duration: 0.3 },
  }

  const animationProps = isSmallScreen
  ? { initial: { opacity: 1 }, animate: { opacity: 1 } } // Forzar opacidad en pantallas pequeñas
  : { initial: "hidden", whileInView: "visible", variants: fadeInUp };

  const Info = [
    {
      title: "Evaluación de Producto",
      info: "Análisis del rendimiento, uso y aceptación de tu producto según la opinión de tu target.",
      icon: <AiOutlineBarChart size={28} color="#FFF"/>,
    },
    {
      title: "Deep Dive",
      info: "Explora a fondo las áreas de oportunidad de tu marca para potenciar su crecimiento",
      icon: <AiOutlineFund size={28} color="#FFF"/>,
    },
    {
      title: "Patrones de Consumo",
      info: "Identifica las características y hábitos de tu consumidor para fortalecer la estrategia de tu marca.",
      icon: <AiOutlinePieChart size={28} color="#FFF"/>,
    },
    {
      title: "Evaluación de Imagen",
      info: "Analiza cómo los consumidores perciben tu marca y optimiza la comunicación visual",
      icon: <AiOutlineEye size={28} color="#FFF"/>,
    },
    {
      title: "Testeo de Concepto",
      info: "Presenta nuevas líneas de productos a tus consumidores y evalúa su aceptación.",
      icon: <AiOutlineExperiment size={28} color="#FFF"/>,
    },
  ];

  const InfoTwo = [
    {
      title: "Benchmarking Competitivo",
      info: "Mide el posicionamiento de tu marca frente a la competencia con rondas de análisis periódicas.",
    },
    {
      title: "Predicción de Tendencias",
      info: "Identifica cambios en el comportamiento del consumidor antes que los demás y descubre oportunidades clave.",
    },
    {
      title: "Optimización en Tiempo Real",
      info: "Ajusta tu estrategia en función de datos actualizados, asegurando que cada decisión impulse el crecimiento de tu marca.",
    }
  ];


  return (
    <PageWrapper isMobile={isMobile}>
      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={!isMobile ? "28px 8px" : "15px 17px"}
        align="center"
        m="0 0 20px 0"
        style={{ backdropFilter: "blur(16px)" }}
      >
        <img
          src={logo}
          alt="logo"
          width={!isMobile ? "154.65px" : "100px"}
          height={"35.33px"}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <Div gap={!isMobile ? "18px" : "5px"} align="center">
          <Button
            variant={"outlined"}
            width={!isMobile ? "157px" : "100px"}
            height={"40px"}
            onClick={() => navigate("/")}
          >
            Inicio
          </Button>
          <Button
            width={!isMobile ? "157px" : "80px"}
            height={"40px"}
            onClick={() => navigate("/login")}
          >
            {!isMobile ? "Login / Registro" : "Login"}
          </Button>
        </Div>
      </Div>
      <AboutSection isMobile={isMobile}/>
      <BrandCarousel isMobile={isMobile} />
      <TitleSection title="Pulpey Zoomins" description="Un Zoomin es una herramienta de análisis profunda que permite explorar
        aspectos clave de un producto o marca, brindando insights valiosos
        para la toma de decisiones estratégicas." />
      <Row
        gap="17px"
        style={{
          flexWrap: "wrap",
        }}
        justify="center"
        m="0 0 50px 0"
      >
        {Info.map((item, index) => (
          <motion.div
            key={index}
            {...animationProps}
            whileHover={hoverEffect}
            transition={{ delay: index * 0.2 }}
          >
            <Div
              direction="column"
              width={!isMobile ? "379px" : "100%"}
              height={!isMobile ? "239px" : "auto"}
              background={theme.colors.whiteBlur}
              style={{ backdropFilter: "blur(30px)" }}
              radius="32px"
              p="26px"
            >
              <Div
                width="53px"
                height="53px"
                background={theme.colors.red}
                radius="50px"
                align="center"
                justify="center"
                m="0 0 16px 0"
              >
                {/* <PackingIcon
                  width="32px"
                  height="32px"
                  stroke={theme.colors.white}
                /> */}
                {item.icon}
              </Div>
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.green300}
                weight={theme.fonts.weight.semibold}
                mb="19px"
              >
                {item.title}
              </Text>
              <Text color={theme.colors.white}>{item.info}</Text>
            </Div>
          </motion.div>
        ))}
      </Row>
      <TitleSection 
        title="Home Pulse" 
        description="Anticipate a la competencia a través de la selección de hogares representativos, 
          Home Pulse es un sistema de muestreo periódico que permite medir 
          KPIs clave en múltiples rondas para evaluar el rendimiento de tu 
          marca a lo largo del tiempo."/>
      <Text
        size={theme.fonts.size.h3}
        color={theme.colors.white}
        weight={theme.fonts.weight.semibold}
        mb="40px"
      >
        ¿Por qué Home Pulse?
      </Text>
      <Div
        direction={!isMobile ? "row" : "column"}
        gap={!isMobile ? "33px" : "12px"}
        m="0 0 40px 0"
      >
        {InfoTwo.map((item, index) => (
          <motion.div
            key={index}
            {...animationProps}
            whileHover={hoverEffect}
            transition={{ delay: index * 0.2 }}
          >
            <Div
              direction="column"
              width={!isMobile ? "400px" : "100%"}
              height={!isMobile ? "190px" : "auto"}
              background={theme.colors.whiteBlur}
              style={{ backdropFilter: "blur(30px)" }}
              radius="32px"
              p="32px 26px"
            >
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.green300}
                weight={theme.fonts.weight.semibold}
                mb="19px"
              >
                {item.title}
              </Text>
              <Text size={theme.fonts.size.h6} color={theme.colors.white}>
                {item.info}
              </Text>
            </Div>
          </motion.div>
        ))}
      </Div>
      <Col align="center" m="0 0 17px 0" gap="10px">
        <Text
          size={theme.fonts.size.h3}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
        >
          ¿Piensas evaluar otras áreas de tu marca o producto?
        </Text>
        <Text size={theme.fonts.size.h4} color={theme.colors.yellowlight}>
          Cuéntanos y te asesoramos
        </Text>
      </Col>
      <Button
        width={"166px"}
        onClick={() => navigate("/contactUs")}
        style={{ marginBottom: isMobile? "90px": "100px" }}
      >
        Contáctanos
      </Button>
    </PageWrapper>
  );
};
