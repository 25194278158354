import React from 'react';
import { motion } from 'framer-motion';
import { Div, Text, Col, Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { AiOutlineAlert, AiOutlineAudit, AiOutlineBoxPlot, AiOutlineDropbox, AiOutlineGlobal, AiOutlineHeart, AiOutlineHome, AiOutlineInbox, AiOutlineInstagram, AiOutlineLike, AiOutlineLink, AiOutlineMessage, AiOutlineRise, AiOutlineShareAlt, AiOutlineSound, AiOutlineStar, AiOutlineTag, AiOutlineTeam, AiOutlineZoomIn,  } from 'react-icons/ai';
import { Button } from '../../../components/Button';

const AboutSection = ({ isMobile }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const experienceList = [
    { icon: <AiOutlineHome/>, text: "Experiencia en casa" },
    { icon: <AiOutlineSound />, text: "Publicidad Orgánica" },
    { icon: <AiOutlineDropbox/>, text: "Product Sampling" },
    { icon: <AiOutlineZoomIn />, text: "Zoomins / Insights" }
  ];

  const highlightWords = (text) => {
    return text.split(' ').map((word, index) => {
      const isHighlight = word.includes('innovadoras') || 
                         word.includes('insights') || 
                         word.includes('PulpeyLovers') ||
                         word.includes('360experience') ||
                         word.includes('interactiva') ||
                         word.includes('experiencias') ||
                         word.includes('impulsar');
      return (
        <motion.span
          key={index}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.02 }}
          style={{
            color: isHighlight ? '#FE4F51' : 'inherit',
          }}
        >
          {word}{' '}
        </motion.span>
      );
    });
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={containerVariants}
    >
      <Div 
        direction={!isMobile? "":"column"} 
        m="0 0 40px 0" width="100%"
        justify="center"
        style={{
            justifyContent: 'center',
            alignItems: 'center',
        }}
      >
        <motion.div variants={itemVariants}>
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            mb="19px"
            style={{ 
              width: "100%",
              lineHeight: '1.6',
              fontSize: isMobile ? '1.1rem' : '1.25rem',
              paddingRight: "25px"
            }}
          >
            {highlightWords(`Somos una plataforma versátil e interactiva que conecta las marcas con su público objetivo.`)}
            <br /><br />
            {highlightWords(`Creamos experiencias innovadoras en los hogares de la comunidad. Pulpey amplifica tus esfuerzos de marketing  al realizar sampling de manera segmentada a una comunidad altamente comprometida de diversos shoppers. Lo que te permite aumentar rápidamente el awareness, impulsar las ventas y recolectar feedback.`)}
            <br /><br />
            {/* <Button
              height={"40px"}
              onClick={() => {}}
            >
              <span style={{ fontWeight: 'bold' }}>Conoce nuestra comunidad</span>
            </Button> */}
          </Text>
        </motion.div>

        <Div 
          direction="column" 
          width="100%" 
          gap={isMobile ? "15px" : "20px"}
        >
          {experienceList.map((item, index) => (
            <motion.div
              key={index}
              variants={{
                hidden: { opacity: 0, x: -20 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.5,
                    delay: index * 0.1
                  }
                }
              }}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 }
              }}
            >
              <Div
                style={{
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '12px',
                  padding: isMobile ? '15px' : '20px',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  transform: `translateX(0px)`
                }}
              >
                <Text 
                  color={theme.colors.white} 
                  style={{ 
                    width: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    fontSize: isMobile ? '0.9rem' : '1rem'
                  }}
                >
                  <span style={{ fontSize: '1.5em' }}>{item.icon}</span>
                  {item.text}
                </Text>
              </Div>
            </motion.div>
          ))}
        </Div>
      </Div>
    </motion.div>
  );
};

export default AboutSection;