import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetListSurveys = () => {
  const data = useQuery(["getSurveys"], () =>
    axiosClient.post(`/SurveyController/GetSurveyList`)
  );
  return data;
};
export default useGetListSurveys;
