import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { theme } from "../../../styles/theme";
import { Div, Text, Row, Col } from "../../../styles/Common";
import { ChevronRightIcon, DataIcon } from "../../../assets/icons";
import useGetListSurveys from "../hooks/useGetListSurveys";
import toast from "react-hot-toast";
import { getSequentialCardStyles } from "../../../utilities/helpers";

const SurveyCard = ({ survey, isMobile, onCardClick }) => {
  const styles = getSequentialCardStyles(survey.categoryId);
  
  return (
    <Div
      width={!isMobile ? "32%" : "90%"}
      height="104.7px"
      radius="24px"
      background={styles.background}
      align="left"
      p="20px 17px 20px 24px"
      onClick={onCardClick}
      style={{ 
        cursor: "not-allowed", 
        opacity: 0.6, 
        position: 'relative', 
      }}
    >
      <Col justify="center" m="0 15.5px 0 0">
        <Div
          background={styles.backgroundLight}
          width="50px"
          height="50px"
          radius="11px"
          align="center"
          justify="center"
        >
          <DataIcon
            stroke={styles.background}
            width="24px"
            height="24px"
          />
        </Div>
      </Col>
      <Col justify="center" width="100%">
        <Text color={styles.color} mb="9px">
          {survey.name}
        </Text>
        <Text
          weight={theme.fonts.weight.bold}
          color={styles.color}
          size={theme.fonts.size.h6}
        >
          {survey.categoryName}
        </Text>
      </Col>
      <Col justify="center">
        <ChevronRightIcon
          width="32px"
          height="32px"
          stroke={styles.color}
        />
      </Col>
    </Div>
  );
};

export const HomePriv = ({ name, isMobile }) => {
  const [dataSurveys, setDataSurveys] = useState([]);
  const getSurveys = useGetListSurveys();

  const { data: dataListSurveys } = getSurveys;

  const filteredSurveys = useMemo(() => 
    dataSurveys.filter(survey => survey.statusId === 147),
    [dataSurveys]
  );

  useEffect(() => {
    setDataSurveys(dataListSurveys?.data?.items.length > 0? dataListSurveys?.data?.items: []);
  }, dataListSurveys)

  const onClickSurvey = (surveyName) => {
    toast.success("¡Muy pronto disponible!");
  };

  return (
    <Div m="0px 0px 100px 0px" p={!isMobile ? "31px 97px 57.23px 98px" : "31px 25px 48px 25px"}>
      <Col>
        <Col>
          <Text
            size={theme.fonts.size.h4}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="10px"
          >
            Hola,{" "}
            <span style={{ color: theme.colors.red }}>
              {name.split(" ")[0].substring(0, 15)}
            </span>{" "}
          </Text>
          <Text
            size={theme.fonts.size.h3}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="32px"
          >
            Bienvenido a la comunidad Pulpey.
          </Text>
          <Text
            size={theme.fonts.size.h6}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="32px"
          >
            Para poder ser parte de las actividades que las marcas tiene para
            ti, por favor recuerda llenar las encuestas, con esta información
            podremos enviarte la PulpeyBox más adecuada a tus gustos. Llena las
            encuestas con confianza y sinceridad. Gracias.
          </Text>
        </Col>
        <Div
          direction={!isMobile ? "row" : "column"}
          gap="17px"
          align={isMobile && "center"}
          style={{
            display: "flex",
            flexWrap: "wrap", 
            justifyContent: "space-between", 
          }}
        >
          {filteredSurveys.map((survey) => (
            <SurveyCard
              key={survey.surveyId}
              survey={survey}
              isMobile={isMobile}
              onCardClick={() => onClickSurvey(survey.name)}
            />
          ))}
          {/* <Div
            width={!isMobile ? "32%" : "90%"}
            height="104.7px"
            radius="24px"
            background={theme.colors.red}
            align="left"
            p="20px 17px 20px 24px"
            style={{ cursor: "pointer" }}
            onClick={onClickSurvey}
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.red50}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.red}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.white} mb="9px">
                Encuesta 1
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
              >
                Alimentos y bebidas
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.white}
              />
            </Col>
          </Div>
          <Div
            width={!isMobile ? "32%" : "90%"}
            height="104.7px"
            radius="24px"
            background={theme.colors.green300}
            align="left"
            p="20px 17px 20px 24px"
            style={{ cursor: "pointer" }}
            onClick={onClickSurvey}
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.white300}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.green300}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.white} mb="9px">
                Encuesta 2
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
              >
                Cuidado Personal
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.white}
              />
            </Col>
          </Div>
          <Div
            width={!isMobile ? "32%" : "90%"}
            height="104.7px"
            radius="24px"
            background={theme.colors.yellowlight}
            align="left"
            p="20px 17px 20px 24px"
            style={{ cursor: "pointer" }}
            onClick={onClickSurvey}
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.yellow50}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.yellowlight}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.gray300} mb="9px">
                Encuesta 3
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.gray300}
                size={theme.fonts.size.h6}
              >
                Cuidado Personal
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.gray300}
              />
            </Col>
          </Div> */}
        </Div>
      </Col>
    </Div>
  );
};

HomePriv.propTypes = {
  name: PropTypes.string,
  isMobile: PropTypes.bool,
};
