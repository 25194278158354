import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginWrapper } from "./styles";
import { Div, Text, Row, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import { Card } from "../../components/Card";
import pulpey from "../../assets/images/pulpey.svg";
import { Input } from "../../components/Input";
import { Password } from "../../components/Password";
import { Link } from "../../components/Link";
import ModalResetPassword from "./components/ModalResetPassword";
import ModalOTP from "./components/ModalOTP";
import mobile from "../../assets/images/Mobile.png";
import { validateLogin } from "../../utilities/validations";
import useAuth from "./hooks/useAuth";
import toast from "react-hot-toast";
import { encrypt } from "../../utilities/helpers";
import { setLocalUser } from "../../core/services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import TitleSection from "./components/title-sections";
import { motion } from "framer-motion";

export const Login = () => {
  const { isMobile } = useOutletContext();

  const authentication = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalOTP(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur", resolver: yupResolver(validateLogin) });

  const handleLogin = (data) => {
    authentication.reset();
    authentication.mutate(
      {
        email: data.email,
        password: encrypt(data.password),
      },
      {
        onSuccess: (res) => {
          setLocalUser(res?.data?.token);
          navigate("/platform");
        },
        onError: (err) => {
          toast.error(err?.response?.data || "Ocurrió un error al intentar iniciar sesión");
        },
      }
    );
  };

  return (
    <LoginWrapper isMobile={isMobile}>
      <ModalResetPassword
        showModal={showModal}
        setShowModalOTP={setShowModalOTP}
        handleCloseModal={handleCloseModal}
        isMobile={isMobile}
      />
      <ModalOTP
        showModalOTP={showModalOTP}
        handleCloseModal={handleCloseModal}
        isMobile={isMobile}
      />

      <Div width={"100%"} height={"99px"} m="0 0 44px 0" />
      <Row
        gap="137px"
        m="0 0 114px 0"
        justify={!isMobile ? "space-between" : "center"}
      >
        {!isMobile && (
          <Div align="left" direction="column" width="640px" m="0 0 0 98px ">
            <img
              src={logo}
              alt="logo"
              width={"175.65px"}
              height={"40.13px"}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />

            <TitleSection 
              title=""
              description="Ingresa para ser parte de la comunidad Pulpey. Disfruta de los beneficios que las marcas tienen para ti." 
            />
            <motion.img
              src={mobile}
              alt="image mobile"
              width="669pxpx"
              height="417pxpx"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              style={{ position: "absolute", left: "0", bottom: "0" }}
            />
          </Div>
        )}
        <Card
          align="center"
          p={!isMobile ? "68.51px 44px 35.43px 45px" : "36px 25px"}
          width={isMobile && "326px"}
        >
          <img src={pulpey} alt="pulpey" width={"59.56px"} height={"51.06px"} />
          <Text
            mt="16px"
            mb="24px"
            size={theme.fonts.size.h3}
            color={theme.colors.white}
          >
            Inicia tu sesión
          </Text>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Col gap="24px" m="0 0 7px 0">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder={"Correo electrónico"}
                    error={errors.email}
                    helper={errors.email ? errors.email.message : ""}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value.toLowerCase());
                    }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Password
                    placeholder={"Contraseña"}
                    {...register("password")}
                    error={errors.password}
                    helper={errors.password ? errors.password.message : ""}
                    {...field}
                  />
                )}
              />
            </Col>

            <Text
              mb="32px"
              size={theme.fonts.size.sm}
              color={theme.colors.white}
              align="right"
              onClick={() => setShowModal(true)}
              style={{ cursor: "pointer" }}
            >
              Olvidé mi contraseña
            </Text>
            <Button
              m="0 0 28px 0"
              htmlType="submit"
              loading={authentication.isLoading}
              disabled={!isValid}
              width={isMobile && "276px"}
            >
              Ingresar
            </Button>
          </form>
          <Row gap="13.5px" justify="center">
            <Text
              size={!isMobile ? theme.fonts.size.default : theme.fonts.size.sm}
              color={theme.colors.white}
              weight={!isMobile && theme.fonts.weight.semibold}
            >
              ¿Aún no tienes cuenta?
            </Text>
            <Link>
              <Text
                size={
                  !isMobile ? theme.fonts.size.default : theme.fonts.size.sm
                }
                color={theme.colors.red}
                weight={!isMobile && theme.fonts.weight.semibold}
                onClick={() => navigate("/user-register")}
              >
                Regístrate aquí
              </Text>
            </Link>
          </Row>
          <Row justify="center" m="20px 0 0 0">
            <Link>
              <Text
                size={
                  !isMobile ? theme.fonts.size.default : theme.fonts.size.sm
                }
                color={theme.colors.red}
                weight={!isMobile && theme.fonts.weight.semibold}
                onClick={() => navigate("/")}
              >
                Volver al inicio
              </Text>
            </Link>
          </Row>
        </Card>
      </Row>
    </LoginWrapper>
  );
};
