import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import { Row, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";
import { Input } from "../../../components/Input";
import { validateProfileInfoStep2 } from "../../../utilities/validations";
import { DatePicker } from "../../../components/DatePicker";
import useGetMaritalStatus from "../../../core/hooks/useGetMaritalStatus";
import useGetGender from "../../../core/hooks/useGetGender";
import useGetRole from "../../../core/hooks/useGetRole";
import useSaveUserProfile from "../hooks/useSaveUserProfile";

export const Step2 = ({ formStep2, setStepNumber, isMobile }) => {
  const queryClient = useQueryClient();
  const saveUserProfile = useSaveUserProfile();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateProfileInfoStep2),
  });
  const watchedFields = watch([
    "name",
    "lastName",
    "maritalStatus",
    "gender",
    "birthdate",
    "role",
  ]);

  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const minDate = dayjs().subtract(18, "years"); 

  const disabledDate = (current) => {
    return (
      current && current.isAfter(dayjs().subtract(18, "years").endOf("day"))
    );
  };

  const getMaritalStatus = useGetMaritalStatus();
  const { data: dataMaritalStatus, isSuccess: isSuccessMaritalStatus } =
    getMaritalStatus;
  const [optionsMaritalStatus, setOptionsMaritalStatus] = useState([]);
  useEffect(() => {
    if (isSuccessMaritalStatus) {
      setOptionsMaritalStatus(
        dataMaritalStatus?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataMaritalStatus, isSuccessMaritalStatus]);

  const getGender = useGetGender();
  const { data: dataGender, isSuccess: isSuccessGender } = getGender;
  const [optionsGender, setOptionsGender] = useState([]);
  useEffect(() => {
    if (isSuccessGender) {
      setOptionsGender(
        dataGender?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataGender, isSuccessGender]);

  const getRole = useGetRole();
  const { data: dataRole, isSuccess: isSuccessRole } = getRole;
  const [optionsRole, setOptionsRole] = useState([]);
  useEffect(() => {
    if (isSuccessRole) {
      setOptionsRole(
        dataRole?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataRole, isSuccessRole]);

  useEffect(() => {
    reset({
      name: formStep2.name,
      lastName: formStep2.lastName,
      maritalStatus: formStep2.maritalStatus,
      gender: formStep2.gender,
      birthdate: formStep2.birthdate,
      role: formStep2.role,
    });
  }, [formStep2]);

  useEffect(() => {
    const isCompleted = watchedFields.every(
      (item) => item !== "" && item !== undefined && item !== null
    );
    setIsCompletedForm(isCompleted);
  }, [watchedFields]);

  const handleSave = (data) => {
    saveUserProfile.reset();
    saveUserProfile.mutate(
      {
        userId: Number(formStep2.userId),
        maritalStatusId: data.maritalStatus,
        genderId: data.gender,
        roleHouseId: data.role,
        cityId: null,
        address: null,
        instruction: null,
        stepNumber: 2,
        phoneNumber: null,
        name: data.name,
        email: null,
        lastName: data.lastName,
        birthDate: data.birthdate,
        phoneNumberCode: null,
        departmentId: null,
        exactAddress: null,
        latitude: null,
        longitude: null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getUserProfile"]);
          setStepNumber(3);
        },
        onError: (err) => {
          toast.error(err?.response?.data?.errors);
        },
      }
    );
  };

  return (
    <>
      <Div gap="32px" m="0 0 32px 0" direction={!isMobile ? "row" : "column"}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              label="Nombre"
              colorLabel={theme.colors.gray200}
              sizeLabel={theme.fonts.size.default}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              background={theme.colors.blue100}
              error={errors?.name}
              helper={errors?.name?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              label="Apellidos"
              colorLabel={theme.colors.gray200}
              sizeLabel={theme.fonts.size.default}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              background={theme.colors.blue100}
              error={errors?.lastName}
              helper={errors?.lastName?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="maritalStatus"
          control={control}
          render={({ field }) => (
            <Select
              label="Estado Civil"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              background={theme.colors.blue100}
              options={optionsMaritalStatus}
              error={errors?.maritalStatus}
              helper={errors?.maritalStatus?.message}
              {...field}
            />
          )}
        />
      </Div>
      <Div
        gap="32px"
        m={!isMobile ? "0 0 93px 0" : "0 0 30px 0"}
        direction={!isMobile ? "row" : "column"}
      >
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <Select
              label="Género"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              background={theme.colors.blue100}
              options={optionsGender}
              error={errors?.gender}
              helper={errors?.gender?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="birthdate"
          control={control}
          render={({ field }) => (
            <DatePicker
              label="Fecha de nacimiento"
              placeholder={"Selecciona una fecha"}
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              format="DD/MM/YYYY"
              background={theme.colors.blue100}
              error={errors?.birthdate}
              helper={errors?.birthdate?.message}
              disabledDate={disabledDate}
              dateRender={(current) => {
                const isDisabled = disabledDate(current);
                const isSelected =
                  field.value && current.isSame(dayjs(field.value), "day");
                return (
                  <div
                    style={{
                      display: isDisabled ? "none" : "block",
                      background: isSelected ? theme.colors.red : "none",
                    }}
                  >
                    {current.date()}
                  </div>
                );
              }}
              disabled={formStep2?.birthdate}
              onOpenChange={(open) => {
                if (open && !watchedFields[4]) {
                  field.onChange(minDate);
                }
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <Select
              label="Rol de tu casa"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width={!isMobile ? "315.95px" : "100%"}
              height="45px"
              background={theme.colors.blue100}
              options={optionsRole}
              error={errors?.role}
              helper={errors?.role?.message}
              {...field}
            />
          )}
        />
      </Div>
      <form onSubmit={handleSubmit(handleSave)}>
        <Button
          width={"146px"}
          htmlType="submit"
          disabled={!isCompletedForm}
          loading={saveUserProfile.isLoading}
        >
          Continuar
        </Button>
      </form>
    </>
  );
};

Step2.propTypes = {
  formStep2: PropTypes.object,
  setStepNumber: PropTypes.func,
  setStepNumber: PropTypes.bool,
};
