import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../styles/theme";
import { Col, Row, Div, Text } from "../../../styles/Common";
import { Card } from "../../../components/Card";
import {
  PhoneIcon,
  EmailIcon,
  PinIcon,
  ChevronRightIcon,
  EditIcon,
} from "../../../assets/icons";
import {
  AiOutlineCopy
} from "react-icons/ai";
import Tabs from "../../../components/Tabs";
import useGetProfileUserSummary from "../hooks/useGetProfileUserSummary";
import { getSequentialCardStyles, getUserInfo } from "../../../utilities/helpers";
import { getLabelById } from "../../../utilities/helpers";
import useGetPhoneCode from "../../../core/hooks/useGetPhoneCode";
import { Tooltip } from "antd";
import toast from "react-hot-toast";
import useGetListSurveys from "../hooks/useGetListSurveys";
import { map } from "lodash";

const SurveyCard = ({ survey, isMobile, onCardClick }) => {
  const styles = getSequentialCardStyles(survey.categoryId);
  
  return (
    <Div
      width={!isMobile ? "219px" : "100%"}
      height="120px"
      radius="24px"
      background={styles.background}
      align="left"
      gap="41px"
      p="20px 17px 20px 24px"
      onClick={onCardClick}
      style={{ 
        cursor: "not-allowed", 
        opacity: 0.6, 
        position: 'relative', 
      }}
    >
      <Col>
        <Text color={styles.color} mb="9px">
          {survey.name}
        </Text>
        <Text
          weight={theme.fonts.weight.bold}
          color={styles.color}
          size={theme.fonts.size.h6}
        >
          {survey.categoryName}
        </Text>
      </Col>
      <Col justify="center">
        <ChevronRightIcon
          width="32px"
          height="32px"
          stroke={styles.color}
        />
      </Col>
    </Div>
  );
};

export const MyAccount = ({ setEditMyAccount, isMobile }) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [dataProfile, setDataProfile] = useState({});
  const [dataSurveys, setDataSurveys] = useState([]);

  const { nameid } = getUserInfo();
  const getProfileUserSummary = useGetProfileUserSummary(nameid);
  const { data } = getProfileUserSummary;

  const getPhoneCode = useGetPhoneCode();
  const { data: dataPhonecode, isSuccess: isSuccessPhoneCode } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);

  const getSurveys = useGetListSurveys();

  const { data: dataListSurveys } = getSurveys;

  const filteredSurveys = useMemo(() => 
    dataSurveys.filter(survey => survey.statusId === 147),
    [dataSurveys]
  );

  useEffect(() => {
    if (isSuccessPhoneCode) {
      setOptionsCodes(
        dataPhonecode?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [dataPhonecode, isSuccessPhoneCode]);

  useEffect(() => {
    setDataSurveys(
      dataListSurveys?.data?.items?.length > 0 ? dataListSurveys.data.items : []
    );
  }, [dataListSurveys]); 

  useEffect(() => {
    setDataProfile({
      phoneNumber: data?.data?.result?.phoneNumber,
      ind: getLabelById(data?.data?.result?.phoneCodeId, optionsCodes),
      email: data?.data?.result?.email,
      address: data?.data?.result?.address,
      name: data?.data?.result?.name,
      lastName: data?.data?.result?.lastName,
      userCode: data?.data?.result?.userId
    });
  }, [data]);


  const initials =
    dataProfile?.name && dataProfile?.lastName
      ? `${dataProfile?.name.charAt(0)}${dataProfile?.lastName.charAt(
          0
        )}`.toUpperCase()
      : "NN";

  const panels = [
    /* {
      tab: "Pendientes por contestar",
      key: "1",
    },
    {
      tab: "Encuestas respondidas",
      key: "2",
    }, */
    {
      tab: "Encuestas",
      key: "1",
    }
  ];

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log("ID copiado al portapapeles!");
        toast.success(`¡ID copiado!`);
      })
      .catch((error) => {});
  }

  const onClickSurvey = () => {
    toast.success("¡Muy pronto disponible!");
  }

  return (
    <>
      <Div
        direction={!isMobile ? "row" : "column"}
        gap="17px"
        m={!isMobile ? "49px 0 136px 0" : "25px 0 150px 0"}
        align={isMobile && "center"}
      >
        <Col>
          <Card width={!isMobile ? "379px" : "326px"} height="auto" p="24px">
            <Row width="100%" justify="space-between" m="0 0 15px 0">
              <Div
                width="80%"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  background={theme.colors.red}
                  align="center"
                  justify="center"
                  style={{cursor: 'pointer'}}
                  onClick={() => {copyToClipboard(dataProfile.userCode)}}
                >
                  <Tooltip title="Copiar ID">
                    <AiOutlineCopy color={theme.colors.white} size={22}/>
                  </Tooltip>
                </Div>
                <Text color={theme.colors.white} style={{fontWeight: 'bold'}}>
                  ID: {dataProfile.userCode}
                </Text>
              </Div>
              <Div
                height="40px"
                width="40px"
                radius="50px"
                background={theme.colors.blue100}
                border={`1px solid ${theme.colors.white}`}
                onClick={() => setEditMyAccount(true)}
                align="center"
                justify="center"
                style={{ cursor: "pointer" }}
              >
                <Tooltip title={"Editar usuario"}>
                  <EditIcon stroke={theme.colors.white} />
                </Tooltip>
              </Div>
            </Row>

            <Col align="center" gap="24px">
              <Div
                height="80px"
                width="80px"
                radius="50px"
                background={theme.colors.red}
                align="center"
                justify="center"
              >
                <Text
                  color={theme.colors.white}
                  weight={theme.fonts.weight.bold}
                  size={theme.fonts.size.h3}
                >
                  {initials}
                </Text>
              </Div>
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.white}
                weight={theme.fonts.weight.bold}
                mb={!isMobile ? "40px" : "25px"}
                style={{ width: !isMobile ? "331px" : "100%" }}
              >
                {dataProfile?.name?.split(" ")[0]}{" "}
                {dataProfile?.lastName?.split(" ")[0]}
              </Text>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  background={theme.colors.red}
                  align="center"
                  justify="center"
                >
                  <PhoneIcon stroke={theme.colors.white} />
                </Div>
                <Text color={theme.colors.white}>
                  {dataProfile.ind} {dataProfile.phoneNumber}
                </Text>
              </Div>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  align="center"
                  justify="center"
                  background={theme.colors.red}
                >
                  <EmailIcon stroke={theme.colors.white} />
                </Div>
                <Text
                  style={{ maxWidth: "calc(100% - 42px - 18px)" }}
                  color={theme.colors.white}
                  align="webkit-left"
                >
                  {dataProfile?.email}
                </Text>
              </Div>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
                style={{ textAlign: "left" }}
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  background={theme.colors.red}
                  align="center"
                  justify="center"
                  style={{ minWidth: "42px" }}
                >
                  <PinIcon stroke={theme.colors.white} />
                </Div>
                <Text
                  color={theme.colors.white}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "calc(100% - 42px - 18px)",
                  }}
                >
                  {" "}
                  {dataProfile?.address}
                </Text>
              </Div>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                style={{ cursor: "pointer" }}
                mt="6px"
                onClick={() => navigate("/ResetPasswordPlatform")}
              >
                Cambiar de contraseña
              </Text>
            </Col>
          </Card>
        </Col>
        <Col>
          <Card
            width={!isMobile ? "775px" : "326px"}
            p="35px 35px 51px 38px"
          >
            <Row>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h3}
                mb="24px"
              >
                Aumenta tus posibilidades
              </Text>
            </Row>
            <Row m="0 0 51px 0">
              <Text
                weight={theme.fonts.weight.semibold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
                style={{ lineHeight: "30px" }}
              >
                Completa las encuestas que tenemos a continuación y aumenta tus
                posibilidades de recibir la{" "}
                <span style={{ color: theme.colors.red }}>Pulpeybox.</span>{" "}
                <br />
                <br />
                Recuerda que estas preguntas ayudan a las marcas a enviarte los
                productos correctos de acuerdo a tu estilo y etapa de vida en la que estás actualmente.
              </Text>
            </Row>
            <Div direction={!isMobile ? "row" : "column"} m="0 0 15px 0">
              <Tabs
                defaultActiveKey="1"
                onChange={handleTabChange}
                activeKey={activeKey}
                size={theme.fonts.size.default}
                gutter={!isMobile ? 42 : 10}
                tabPosition="left"
              >
                {panels.map((panel) => (
                  <Tabs.Panel tab={panel.tab} key={panel.key} />
                ))}
              </Tabs>
            </Div>
            <Div
              direction={!isMobile ? "row" : "column"}
              gap="21.3px"
              align={isMobile && "center"}
              style={{
                display: "flex",
                flexWrap: "wrap", 
                justifyContent: "flex-start", 
              }}
            >
              {filteredSurveys.length === 0 ? (
                <Text
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.white}
                  size={theme.fonts.size.sm}
                  style={{ lineHeight: "30px" }}
                >Lo sentimos, no hay encuestas disponibles para ti en este momento. ¡Estaremos agregando más pronto!</Text> 
              ) : (
                filteredSurveys.map((survey) => (
                  <SurveyCard
                    key={survey.surveyId}
                    survey={survey}
                    isMobile={isMobile}
                    onCardClick={() => onClickSurvey(survey.name)}
                  />
                ))
              )}
              {/* <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.red}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
                style={{ cursor: "pointer" }}
                onClick={onClickSurvey}
              >
                <Col>
                  <Text color={theme.colors.white} mb="9px">
                    Encuesta 1
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.white}
                    size={theme.fonts.size.h6}
                  >
                    Alimentos y bebidas
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.white}
                  />
                </Col>
              </Div>
              <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.green300}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
                style={{ cursor: "pointer" }}
                onClick={onClickSurvey}
              >
                <Col>
                  <Text color={theme.colors.white} mb="9px">
                    Encuesta 2
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.white}
                    size={theme.fonts.size.h6}
                  >
                    Cuidado personal
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.white}
                  />
                </Col>
              </Div>
              <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.yellowlight}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
                style={{ cursor: "pointer" }}
                onClick={onClickSurvey}
              >
                <Col>
                  <Text color={theme.colors.gray300} mb="9px">
                    Encuesta 3
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.gray300}
                    size={theme.fonts.size.h6}
                  >
                    Salud y Medicamentos
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.gray300}
                  />
                </Col>
              </Div> */}
            </Div>
          </Card>
        </Col>
      </Div>
    </>
  );
};

MyAccount.propTypes = {
  setEditMyAccount: PropTypes.func,
  isMobile: PropTypes.bool,
};
