import styled from "styled-components";
import backImage from "../../assets/images/backgroundInsights.png";
import { motion } from "framer-motion";

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${backImage});
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: ${(props) =>
    props.isMobile ? "0 25px 48px 25px" : "0 98px 48px 98px"};
`;

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 40px 0;
  margin-bottom: 40px;
`;

export const GradientOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 20;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: ${({ isMobile }) => (isMobile ? '20px' : '150px')};
    height: 100%;
    background: linear-gradient(
      to right,
      ${({ isMobile }) => (isMobile ? '#272727 0%' : '#5F3132 0%')},
      ${({ isMobile }) => (isMobile ? 'rgba(39, 39, 39, 0) 100%' : 'rgba(80, 47, 48, 0) 100%')}
    );
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    background: linear-gradient(
      to left,
      #272727 0%,
      rgba(39, 39, 39, 0) 100%
    );
  }
`;

export const CarouselTrack = styled(motion.div)`
  display: flex;
  position: relative;
`;

export const CarouselItem = styled.div`
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.32);
  border-radius: 10px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const BrandImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`;