import React from "react";
import { Row, Text, Div } from "../../styles/Common";
import { CopyrightIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { SocialIconsContainer, TermsLink, FooterContainer } from "./styles";

import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTikTok,
  AiOutlineYoutube,
  AiOutlineLinkedin,
  AiOutlineMail,
  AiOutlineX
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import socialLinks from "../../assets/json/social_links.json";

const icons = {
  AiOutlineFacebook: <AiOutlineFacebook />,
  AiOutlineInstagram: <AiOutlineInstagram />,
  AiOutlineTikTok: <AiOutlineTikTok />,
  AiOutlineYoutube: <AiOutlineYoutube />,
  AiOutlineLinkedin: <AiOutlineLinkedin />,
  AiOutlineMail: <AiOutlineMail />,
  AiOutlineX: <AiOutlineX/>
};


const CopywriteFooter = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <FooterContainer direction="column" align="center" gap="20px">
      <SocialIconsContainer>
        {socialLinks.map((link) => (
          <>
            {link.social? 
              <Tooltip title={link.name}>
                <a
                  key={link.id}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.name}
                >
                  {icons[link.icon]}
                </a>
              </Tooltip> :
              <Tooltip title={link.name}>
                <span
                  key={link.id}
                  href="#"
                  style={{cursor: 'pointer'}}
                  onClick={() => {navigate(link.url)}}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.name}
                >
                  {icons[link.icon]}
                </span>
              </Tooltip> 
            } 
          </>
        ))}
      </SocialIconsContainer>

      <Row justify="center" gap="20px">
        <TermsLink 
          href={`${process.env.PUBLIC_URL}/TerminosYCondiciones.pdf`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Términos y Condiciones
        </TermsLink>
      </Row>

      <Row justify="center" align="center" gap="5px" m="10px">
        <CopyrightIcon width="20px" height="20px" />
        <Text size={theme.fonts.size.sm} color={theme.colors.gray200}>
          {/* currentYear */} 2024 Pulpey. Todos los derechos reservados
        </Text>
      </Row>
    </FooterContainer>
  );
};

export default CopywriteFooter;
