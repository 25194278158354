import styled from "styled-components";
import { theme } from "../../styles/theme";

export const WrapperContainer = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || "402px"};
  width: ${({ width }) => width || "100%"};
  margin: 0 auto;
`;

export const CarouselContainer = styled.div`
  position: relative;
  height: ${({ height }) => height || "639px"};
  overflow: hidden;
  border-radius: 68px;
  background: ${theme.colors.whiteBlur};
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Skeleton = styled.div`
  width: ${({ width_image }) => width_image || "100%"};
  height: ${({ height_image }) => height_image || "639px"};
  background: #e0e0e0; 
  border-radius: 68px;
  animation: skeleton-loading 1.5s infinite linear;

  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #c4c4c4;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
`;

export const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  padding: 12px;
  font-size: 24px; 
  border-radius: 20%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, box-shadow 0.3s ease; 

  &:first-child {
    left: 15px; 
  }

  &:last-child {
    right: 15px; 
  }

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
  }

  &:active {
    background: rgba(0, 0, 0, 0.9);
    transform: translateY(-50%) scale(0.95); 
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  top: -15px;
  left: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  z-index: 10;
`;

export const ProgressBarItem = styled.div`
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: white;
  transition: width 0.3s linear;
`;