import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion"; 
import { 
  CarouselContainer, 
  CarouselWrapper, 
  CarouselItem, 
  NavButton, 
  Skeleton, 
  ProgressBarContainer, 
  ProgressBarItem, 
  ProgressBarFill, 
  WrapperContainer
} from "./styles";
import images from "../../assets/json/images.json";  
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import pulpeyBox from "../../assets/images/pulpeyBox.png";
import { useOutletContext } from "react-router-dom";

const Carousel = ({ width, height, max_width, width_image, height_image }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false); 
  const [imageError, setImageError] = useState(false); 
  const [progress, setProgress] = useState(0);
  const { isMobile } = useOutletContext();

  const carouselWidth = width || "100%";
  const carouselHeight = height || "639px";
  const carouselMaxWidth = max_width || "402px";
  const carouselWidthImage = width_image || "402px";
  const carouselHeightImage = height_image || "639px";

  const initialImage = {
    id: 0, 
    URL: pulpeyBox,  
    alt: "Pulpey box",
  };
  const imagesTotal = [initialImage, ...images];
  const totalImages = imagesTotal.length;

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalImages - 1 : prevIndex - 1));  
    setProgress(0);
  };

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);  
    setProgress(0);
  }, [totalImages]);

  const checkImageUrl = useCallback((url) => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
      setImageError(false);
    };
    img.onerror = () => {
      setImageError(true);
      setImageLoaded(false);
    };
    img.src = url;  
  }, []);

  useEffect(() => {
    checkImageUrl(imagesTotal[currentIndex]?.URL);  
  }, [currentIndex, imagesTotal, checkImageUrl]);

  useEffect(() => {
    let intervalId;
    if (!isPaused) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            goToNext();
            return 0;
          }
          return prevProgress + 1;
        });
      }, 30);
    }
    return () => clearInterval(intervalId);
  }, [isPaused, goToNext]);

  return (
    <WrapperContainer>
      {/* <ProgressBarContainer>
        {imagesTotal.map((_, index) => (
          <ProgressBarItem key={index}>
            <ProgressBarFill 
              progress={index === currentIndex ? progress : (index < currentIndex ? 100 : 0)} 
            />
          </ProgressBarItem>
        ))}
      </ProgressBarContainer> */}

      <CarouselContainer
        width={carouselWidth}
        height={height_image}
        maxWidth={carouselMaxWidth}
        onMouseEnter={() => !isMobile && setIsPaused(true)} 
        onMouseLeave={() => !isMobile && setIsPaused(false)} 
        onTouchStart={() => setIsPaused(true)} 
        onTouchEnd={() => setIsPaused(false)} 
        style={{ backdropFilter: "blur(30px)" }}
      >
        <NavButton onClick={goToPrevious} aria-label="Previous image">
          <AiOutlineLeft />
        </NavButton>

        <CarouselWrapper>
          <AnimatePresence initial={false} custom={currentIndex}>
            <motion.div
              key={currentIndex}
              custom={currentIndex}
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: "0%" }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ type: "tween", duration: 0.5 }}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CarouselItem>
                {imageError || !imageLoaded ? (
                  <Skeleton width_image={carouselWidthImage} height_image={carouselHeightImage} />
                ) : (
                  <img
                    src={imagesTotal[currentIndex].URL}
                    alt={imagesTotal[currentIndex].alt || "Pulpey Box"}
                    width={carouselWidthImage}
                    height={carouselHeightImage}
                    style={{
                      objectFit: isMobile ? "cover" : "", 
                      borderRadius: "68px"
                    }}
                  />
                )}
              </CarouselItem>
            </motion.div>
          </AnimatePresence>
        </CarouselWrapper>

        <NavButton onClick={goToNext} aria-label="Next image">
          <AiOutlineRight />
        </NavButton>
      </CarouselContainer>
    </WrapperContainer>
  );
};

export default React.memo(Carousel);